<template>
  <!-- Essay Submission -->
  <div>
    <!-- Essay Submission Card Header -->
    <b-card
      bg-variant="transparent"
      class="material-card border-light-secondary mb-1"
    >
      <div class="d-flex">
        <div class="mr-1">
          <b-avatar
            v-if="taskData.quizType === 'multiple'"
            rounded="lg"
            variant="light-success"
          >
            <i class="card-icon ti ti-list-check" />
          </b-avatar>

          <b-avatar
            v-if="taskData.quizType === 'essay'"
            rounded="lg"
            variant="light-info"
          >
            <i class="card-icon ti ti-notes " />
          </b-avatar>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column ">
              <span v-if="taskData.quizType === 'multiple'" class="text-muted ">
                Pilihan Ganda
              </span>
              <span v-if="taskData.quizType === 'essay'" class="text-muted ">
                Essay
              </span>
              <h6 class="font-weight-bolder small-gap-bottom">
                Tugas : {{ taskData.name }}
              </h6>
            </div>
          </div>

          <b-card-text>
            {{ taskData.description }}
          </b-card-text>
        </div>
      </div>
    </b-card>

    <!-- Tempate Quiz -->
    <div class="my-1 d-flex justify-content-end">
      <b-button class="mr-1" variant="success" @click="downloadTemplate">
        <i class="ti ti-download" />
        Download Template
      </b-button>

      <input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ref="file"
        type="file"
        style="display: none"
        @change="importQuestion"
      />

      <b-button variant="warning" @click="$refs.file.click()">
        <i class="ti ti-table-import" />
        Import Soal
      </b-button>
    </div>

    <!-- Essay -->
    <b-card
      v-for="(quiz, quizIndex) in allQuiz"
      :key="quizIndex"
      bg-variant="transparent"
      class="material-card border-light-secondary p-1 mb-1 "
    >
      <b-row>
        <b-col md="12">
          <!-- Exam Detail -->
          <div v-if="false" class="d-flex justify-content-between mb-2">
            <!-- Teacher Info -->
            <div>
              <h6 class="font-weight-bolder small-gap-bottom">
                Guru Kelas
              </h6>
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    src="/img/13-small.d796bffd.png"
                    text="avatarText('Bambang')"
                    variant="`light-primary`"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  Sukmadi, S.Pd.
                </span>
                <small class="text-muted">NIP. 1404591288123</small>
              </b-media>
            </div>

            <!-- Class Info -->
            <div>
              <h6 class="font-weight-bolder small-gap-bottom">
                Kelas
              </h6>
              <span>
                Geografi
              </span>
            </div>

            <!-- Progress Info -->
            <div class="w-30">
              <div class="d-flex justify-content-between">
                <h6 class="font-weight-bolder small-gap-bottom">
                  Progress
                </h6>
                <h6 class="font-weight-bolder small-gap-bottom">
                  05:00 Menit / 2 Jam
                </h6>
              </div>
              <b-progress class="small-gap-top" value="2" max="100" />
            </div>
          </div>

          <!-- Exam Content -->
          <div>
            <h6 class="font-weight-bolder small-gap-bottom">
              Pertanyaan
            </h6>

            <!-- Exam Question -->
            <div class="d-flex mb-1">
              <b-avatar class="small-gap-right" variant="primary">
                {{ quizIndex + 1 }}
              </b-avatar>
              <!-- <p class="font-weight-normal">
                {{ quiz.question }}
              </p> -->
              <b-form-textarea
                v-model="quiz.question"
                no-resize
                placeholder="Masukkan Pertanyaan"
              />
            </div>

            <!-- Exam Answer Add -->
            <div class="d-flex justify-content-end">
              <b-button
                variant="outline-danger"
                @click="deleteQuestion(quizIndex)"
              >
                <i class="ti ti-trash"></i>
                Hapus Soal
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-button class="w-100" variant="outline-primary" @click="addQuestion">
      <i class="ti ti-plus" />
      Tambah Soal
    </b-button>

    <b-col md="12" class="border-top pt-1 my-1">
      <div class="d-flex justify-content-end">
        <b-button variant="primary" class="ml-1" @click="submitForm">
          <b-spinner
            v-if="componentLoading"
            class="mr-1"
            variant="light"
            small
          />
          <i
            v-if="!componentLoading"
            class="align-middle ti ti-device-floppy small-gap-right"
          />
          <span class="align-middle ">Simpan</span>
        </b-button>
      </div>
    </b-col>
  </div>
</template>

<script>
import { read, utils, writeFile } from 'xlsx'
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'

import {
  BBadge,
  BRow,
  BCol,
  BCard,
  BButton,
  BCardText,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormRadio,
  BInputGroupPrepend,
  BAvatar,
  BMedia,
  BProgress,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormRadio,
    BInputGroupPrepend,
    BAvatar,
    BMedia,
    BProgress,
    BFormGroup,
    BSpinner,
  },
  data() {
    return {
      // state
      isLoading: true,
      componentLoading: false,

      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      taskId: this.$router.currentRoute.params.taskId,
      taskData: {},
      // Quiz Data
      allQuiz: [
        {
          questionNumber: 1,
          question: '',
        },
      ],
    }
  },
  async mounted() {
    client
      .get(
        `/teachers/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}/exams`
      )
      .then((response) => {
        this.taskData = response.data.data
        this.taskData.quizType = this.taskData.exam_questions[0].type
        this.allQuiz = this.taskData.exam_questions
      })
  },
  methods: {
    addQuestion() {
      this.allQuiz.push({
        questionNumber: this.allQuiz.length + 1,
        question: '',
      })
    },
    deleteQuestion(quizIndex) {
      if (this.allQuiz[quizIndex].id != undefined) {
        this.deleteQuiz(this.allQuiz[quizIndex].id)

        this.allQuiz.splice(quizIndex, 1)
      } else {
        this.allQuiz.splice(quizIndex, 1)
      }
    },
    async deleteQuiz(id) {
      await client
        .delete(
          `/teachers/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}/exams/question/${id}`
        )
        .then((response) => {
          this.showToast(
            'success',
            'Check',
            'Berhasil',
            'Berhasil menghapus soal'
          )
        })
    },
    generateQuizNumber() {
      this.allQuiz.forEach((quiz, index) => {
        quiz.questionNumber = index + 1
      })
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },

    convertQuizToJson() {
      const quizJson = {
        questions: [],
      }

      this.allQuiz.forEach((quiz) => {
        const questionObject = {
          question_number: quiz.questionNumber,
          question: quiz.question,
          explanation: '-',
          type: this.taskData.quizType,
        }

        quizJson.questions.push(questionObject)
        console.log(quizJson)
      })

      return quizJson
    },

    async submitForm() {
      const url = `/teachers/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}/exams`

      this.componentLoading = true

      this.generateQuizNumber()
      const data = this.convertQuizToJson()

      await client
        .post(url, data)
        .then((response) => {
          // show success toast
          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            'Tugas berhasil ditambah'
          )

          // delete local storage data
          // localStorage.removeItem('taskData')
          this.componentLoading = false

          // redirect to classroom detail
          this.$router.push({
            name: checkAuthorizeRole('classroom-detail-task'),
            params: { id: this.classroomId, contentId: this.contentId },
          })
        })
        .catch((error) => {
          this.componentLoading = false
          console.error(error)
        })
    },

    async importQuestion(e) {
      const file = e.target.files[0]
      console.log(file)

      const ab = await file.arrayBuffer()

      /* parse workbook */
      const wb = read(ab)

      /* update data */
      const json = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])

      let questionData = []

      // mapping into question data
      json.map((row) => {
        if (row.questionNumber) {
          questionData.push(row)
        }
      })

      this.allQuiz = questionData
    },

    downloadTemplate() {
      window.location = '/template/Quiz_Essay_Template.xlsx'
    },
  },
}
</script>

<style lang="scss" scoped>
.round-button {
  padding: 0.2%;
  margin: 0.7%;
  font-size: 0.7rem;
  height: 2.5rem;
  width: 2.5rem;
}

.w-30 {
  width: 30%;
}

.exam-answer-options .form-control[readonly] {
  cursor: pointer;
  background-color: white !important;
}

.exam-answer-options .delete-button {
  height: 2.714rem;
}
</style>
